.static-view {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.contact-banner {
			background-image: url(RESOURCE/img/kontakt-banner.jpg);
		}
	}

	.inner-banner {
		&.about-us {
			background-image: url(RESOURCE/img/ueber-uns-banner.jpg);
		}
	}

	&.hausordnung-v1 {
		ul {
			list-style: disc;
			padding-left: 15px;

			li {
				span {
					display: flex;
					font-size: 16px;
				}
			}
		}

		h3 {
			display: flex;
			flex-direction: column;

			span {
				font-size: 16px;
				margin-top: 5px;
			}

		}
	}



	&.about-us-v1 {
		.card-wraper {
			display: flex;
			margin-bottom: 30px;

			@media (max-width:767px) {
				flex-direction: column;
			}


			.card-img-body {
				width: 350px;

				@media (max-width:767px) {
					width: 100%;
				}


			}

			.card-body {
				width: calc(100% - 350px);
				padding-top: 0;
				padding-bottom: 0;

				@media (max-width:767px) {
					padding: 15px 0;
					width: 100%;
				}

			}
		}

		.heading-img {
			span {
				font-size: 20px;
			}
		}
	}
}